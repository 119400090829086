@use "../base/vars" as *;

.law{
    max-width: 780px;
    margin: 70px auto 0;
    padding-inline: 16px;
    
    @include media_query(){
        margin-top: 50px;
    }
    
    .law_table{
        margin-top: 45px;
        
        th,
        td{
            border: solid 1px $font-color;
            @include rem(13);
            line-height: 1.8;
            font-weight: 500;
            text-align: left;
            padding: 1em;
            
            @include media_query(){
                @include rem(12);
                padding: .8em;
            }
        }
        
        th{
            white-space: nowrap;
            
            @include media_query(){
                white-space: normal;
                min-width: 10em;
            }
        }
        
        td{
            small{
                @include rem(11);
            }
            
            a{
                color: $font-color;
                text-decoration: none;
                padding: 5px;
                margin-left: -5px;
                transition: color .25s, background-color .25s;
        
                &:hover{
                    background-color: $font-color;
                    color: #fff;
                }
            }
        }
    }
}
