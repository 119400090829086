@use "../base/vars" as *;

/* *** *** *** *** ***
** NEWSのアーカイブページ
*** *** *** *** *** */
.news_archive{
    .news{
        border: none;
        padding: 0;
        margin: 70px 0 0;
    }
}





.single{
    margin-top: 50px;
    
    .single_head{
        .single_title_ja{
            @include rem(18);
            line-height: 1.8;
            letter-spacing: .17em;
        }
        
        .single_title_en{
            margin-top: 10px;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            @include rem(16);
            line-height: 1.8;
            letter-spacing: .08em;
        }
        
        .datetime{
            margin-top: 20px;
            
            time{
                @include rem(14);
                line-height: 1.8;
                letter-spacing: .17em;
            }
        }
    }
    
    .single_body{
        margin-top: 50px;
        
        h1{
            @include rem(18);
            font-weight: 500;
            line-height: 1.8;
            letter-spacing: .17em;
            
            &:not(:first-child){
                margin-top: 1.5em;
            }
        }
        
        h2{
            @include rem(18);
            font-weight: 500;
            line-height: 1.8;
            letter-spacing: .17em;
            
            &:not(:first-child){
                margin-top: 1.5em;
            }
        }
        
        h3{
            @include rem(16);
            font-weight: 500;
            line-height: 1.8;
            letter-spacing: .17em;
            
            &:not(:first-child){
                margin-top: 1.5em;
            }
        }
        
        h4,
        h5,
        h6{
            @include rem(14);
            font-weight: 500;
            line-height: 1.8;
            letter-spacing: .17em;
            
            &:not(:first-child){
                margin-top: 1.5em;
            }
        }
        
        p{
            @include rem(14);
            line-height: 2;
            letter-spacing: .08em;
            
            a{
                display: inline;
                color: $font-color;
                
                transition: background-color .25s, color .25s;
                
                &:hover{
                    text-decoration: none;
                    background-color: $font-color;
                    color: #fff;
                }
            }
        }
        
        a{
            display: inline-block;
            color: $font-color;
            padding: .5em 3px;
            
            transition: background-color .25s, color .25s;
            
            &:hover{
                text-decoration: none;
                background-color: $font-color;
                color: #fff;
            }
        }
        
        * + p{
            margin-top: 1em;
        }
        
        img{
            max-width: 100%;
            height: auto;
            margin-bottom: 16px;
            
            &:not(:first-child){
                margin-top: 16px;
            }
        }
        
        ul{
            margin: 1em 0;
            padding-left: 1.2em;
            
            li{
                @include rem(14);
                line-height: 1.8;
                letter-spacing: .08em;
            }
        }
        
        table{
            width: 100%;
            
            thead{
                th,td{
                    @include rem(12);
                }
            }
            
            tr{
                max-width: 10em;
                border-bottom: solid 1px $font-color;
            }
            
            th,td{
                text-align: left;
                @include rem(14);
                line-height: 1.8;
                letter-spacing: .08em;
                padding: .5em;
            }
        }
        
        blockquote{
            margin: 1em 0;
            background-color: lighten($bg-color, 2%);
            @include rem(13);
            line-height: 1.8;
            letter-spacing: .08em;
            padding: .5em .5em .5em 1em;
            border-left: solid 5px darken($bg-color, 10%);
            // font-weight: bold;
            font-weight: 500;
        }
    }
}





.return_link{
    margin-top: 50px;
    text-align: center;
    
    a{
        display: inline-block;
        @include rem(14);
        color: $font-color;
        padding: 5px;
        
        transition: background-color .25s, color .25s;
                
        &:hover{
            background-color: $font-color;
            color: #fff;
            text-decoration: none;
        }
    }
}

.return_btn{
    margin-top: 50px;
    
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 250px;
        height: 40px;
        margin: 0 auto;
        background-color: $font-color;
        color: #fff;
        text-decoration: none;
        @include rem(16);
        // font-weight: bold;
        font-weight: 500;
        letter-spacing: .17em;
        line-height: 2;
        border: solid 1px $font-color;
        
        transition: background-color .25s, color .25s;
        
        &:hover{
            background-color: transparent;
            color: $font-color;
        }
        
        @include media_query(){
            height: 45px;
            @include rem(13);
        }
        
        small{
            @include rem(13);
            
            @include media_query(){
                @include rem(11);
            }
        }
    }
}
