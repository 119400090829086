@use "../base/vars" as *;

html{
    scroll-behavior: smooth;
}

body{
    background-color: $bg-color;
    font-feature-settings: "palt" 1;
}





/* *** *** *** *** ***
** header
*** *** *** *** *** */
.header{
    .header_inner{
        display: flex;
        max-width: 1082px;
        margin: 0 auto;
        padding: 75px 16px 0;
        
        @include media_query(){
            display: block;
            padding: 30px 16px 0;
        }
        
        .logo{
            width: 100%;
            max-width: 426px;
            
            @include media_query(){
                text-align: center;
            }
            
            img{
                width: 100%;
                max-width: 426px;
                height: auto;
                
                @include media_query(){
                    max-width: 200px;
                }
            }
        }
    }
}





/* *** *** *** *** ***
** nav
*** *** *** *** *** */
.nav{
    margin-left: auto;
    
    @include media_query(){
        margin: 30px 16px 0;
    }
    
    ul{
        display: flex;
        gap: 40px;
        
        @include media_query(){
            justify-content: center;
        }
        
        li{
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            @include rem(14);
            letter-spacing: .2em;
            
            a{
                text-decoration: none;
                color: $font-color;
                
                transition: opacity .25s;
                
                &:hover{
                    opacity: .7;
                }
            }
        }
    }
}





/* *** *** *** *** ***
** footer
*** *** *** *** *** */
.footer{
    border-top: solid 1px $font-color;
    margin-top: 90px;
    padding: 90px 0 50px;
    
    @include media_query(){
        margin-top: 50px;
        padding: 50px 16px 30px;
    }
    
    .footer_inner{
        text-align: center;
    }
    
    .contact{
        h2{
            @include rem(16);
            letter-spacing: .1em;
            line-height: 1.8;
            font-weight: 500;
            
            @include media_query(){
                @include rem(15);
                
                span{
                    display: none;
                }
            }
        }
        
        p{
            margin-top: 20px;
            
            @include media_query(){
                margin-top: 10px;
            }
            
            a{
                display: inline-block;
                text-decoration: none;
                padding: 5px;
                @include rem(13);
                line-height: 1.8;
                // font-weight: bold;
                font-weight: 500;
                color: $font-color;
                
                transition: background-color .25s, color .25s;
                
                &:hover{
                    background-color: $font-color;
                    color: #fff;
                }
            }
        }
    }
    
    .purpose{
        margin-top: 30px;
        
        p{
            @include rem(13);
            // font-weight: bold;
            font-weight: 500;
            letter-spacing: .17em;
            line-height: 2;
            
            @include media_query(){
                @include rem(12);
                letter-spacing: .08em;
            }
        }
    }
    
    .link_sns{
        list-style: none;
        margin-top: 80px;
        
        @include media_query(){
            margin-top: 40px;
        }
        
        li{
            a{
                display: inline-block;
                padding: 5px;
                // border-radius: 5px;
                
                transition: background-color .25s;
                
                &:hover{
                    background-color: $font-color;
                    
                    img{
                        filter: brightness(0) invert(1);
                    }
                }
                
                img{
                    width: 36px;
                    height: 36px;
                    object-fit: contain;
                    
                    transition: filter .25s;
                }
            }
        }
    }
    
    .law{
    text-align: center;
    margin-top: 50px;
    
    a{
        @include rem(11);
        line-height: 1.5;
        text-decoration: none;
        color: $font-color;
        padding: 5px;
        
        transition: color .25s, background-color .25s;
        
        &:hover{
            background-color: $font-color;
            color: #fff;
        }
    }
}
    
    .copy{
        margin-top: 30px;
        font-family: 'Roboto', sans-serif;
        @include rem(12);
        letter-spacing: .1em;
        line-height: 2;
        
        @include media_query(){
            margin-top: 20px;
            @include rem(10);
        }
    }
}






/* *** *** *** *** ***
** utility
*** *** *** *** *** */

// 欧文のセクションごとの見出し
.section_title_en{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    @include rem(16);
    letter-spacing: .2em;
    line-height: 2;
    text-align: center;
}

// 和文のセクションごとの見出し
.section_title_ja{
    // font-weight: bold;
    font-weight: 500;
    @include rem(16);
    letter-spacing: .17em;
    line-height: 1.8;
    text-align: center;
    
    span{
        & + span{
            margin-left: .5em;
        }
        @include media_query(){
            display: block;
            text-align: center;
        }
    }
}

.sp_br{
    display: none;
    
    @include media_query(){
        display: block;
    }
}





/* *** *** *** *** ***
** ローディング
*** *** *** *** *** */
.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: load 1.8s infinite ease-in-out;
}
.loader {
    color: $font-color;
    font-size: 3px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
}
.loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
}
.loader:after {
    left: 3.5em;
}
@keyframes load {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
