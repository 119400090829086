@charset "UTF-8";

//*------------------------------------*//
//	Base
//*------------------------------------*//

@use 'base/reset';
@use 'base/default';






//*------------------------------------*//
//	pages
//*------------------------------------*//
@use "page/common";
@use "page/index";
@use "page/news";
@use "page/aos";
@use "page/law";




//*------------------------------------*//
//	Fonts
//*------------------------------------*//
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&family=Roboto:wght@400;500;700&display=swap');
