@use "sass:math";

///*------------------------------------*//
//    Fonts
//*------------------------------------*//
$font-family: 'Noto Sans JP', "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
$line-height: 1.5;




///*------------------------------------*//
//    Colors
//*------------------------------------*//
$main-color: #fff;
$accent-color: #f00;
$font-color: #231815;
$base-color: #fff;
$bg-color: #E1D7C3;




///*------------------------------------*//
//     Mixin
//*------------------------------------*//

//メディアクエリ挿入
@mixin media_query($width:640) {
    @media screen and (max-width: $width+px) {
        @content;
    }
}

//フォントサイズ計算
@mixin rem($size, $base: 16) {
    font-size: math.div($size, $base) + rem;
}
