@use "../base/vars" as *;

/* *** *** *** *** ***
** introduction
*** *** *** *** *** */
.introduction{
    max-width: 762px;
    margin: 70px auto 0;
    
    @include media_query(){
        margin: 50px 0 0;
        padding: 0;
    }
    
    .introduction_inner{
        max-width: 762px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        // gap: 40px;
        margin-inline: auto;
        padding-inline: 16px;
        
        @include media_query(){
            grid-template-columns: 1fr;
            gap: 30px;
        }
        
        .introduction_ja{
            h2{
                @include rem(18);
                font-weight: 500;
                letter-spacing: .17em;
            }
            
            p{
                margin-top: 25px;
                @include rem(13);
                // font-weight: bold;
                font-weight: 500;
                line-height: 2;
                letter-spacing: .08em;
                
                @include media_query(){
                    margin-top: 20px;
                    letter-spacing: 0;
                }
            }
        }
        
        .introduction_en{
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            
            h2{
                @include rem(13);
                font-weight: 500;
                letter-spacing: .08em;
            }
            
            p{
                margin-top: 30px;
                @include rem(11);
                line-height: 2.1;
                letter-spacing: .08em;
                
                @include media_query(){
                    margin-top: 25px;
                    letter-spacing: 0em;
                }
            }
        }
    }
    
    .silider{
        max-width: 730px;
        margin: 80px auto 0;
        
        @include media_query(){
            margin-top: 50px;
        }
        
        &:hover{
            .swiper-button-prev,
            .swiper-button-next{
                visibility: visible;
                opacity: 1;
            }
        }
        
        --swiper-navigation-color: #E1D7C3;
        --swiper-navigation-size: 24px;
        
        .swiper-button-prev,
        .swiper-button-next{
            visibility: hidden;
            opacity: 0;
            transition: visibility .25s, opacity .25s;
        }
        
        img{
            width: 100%;
            height: auto;
        }
    }
}






/* *** *** *** *** ***
** about
*** *** *** *** *** */
.about{
    max-width: 762px;
    margin-inline: auto;
    padding: 70px 16px 0;
    
    @include media_query(){
        padding: 50px 16px 0;
    }
    
    .about_text{
        margin-top: 45px;
            
        @include media_query(){
            margin-top: 30px;
        }
        
        .about_text_ja{
            @include rem(13);
            
            h3{
                text-decoration: underline;
                font-weight: 500;
            }
            
            p{
                margin-top: 10px;
                // font-weight: bold;
                font-weight: 500;
                line-height: 2;
                letter-spacing: .17em;
            }
        }
        
        .about_text_en{
            margin-top: 45px;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            @include rem(11);
            line-height: 2;
            letter-spacing: .08em;
            
            @include media_query(){
                margin-top: 30px;
            }
            
            h3{
                text-decoration: underline;
                letter-spacing: .1em;
            }
            
            p{
                margin-top: 8px;
            }
        }
    }
}

.about_foodloss{
    margin: 45px 0 0;
    border: solid 1px $font-color;
    border-radius: 10px;
    background-color: $bg-color;
    
    @include media_query(){
        margin-top: 40px;
    }
    
    .about_foodloss_inner{
        padding: 30px;
        
        @include media_query(){
            padding: 20px 16px 30px;
        }
        
        .about_foodloss_ja{
            display: grid;
            grid-template-columns: 89px 160px 1fr;
            gap: 20px 35px;
            
            @include media_query(){
                grid-template-columns: 89px 1fr;
                align-items: center;
            }
            
            img{
                mix-blend-mode: multiply;
            }
            
            h3{
                @include rem(13);
                font-weight: 500;
                line-height: 2;
                letter-spacing: .17em;
            }
            
            p{
                @include rem(13);
                line-height: 1.8;
                letter-spacing: .1em;
                text-align: justify;
                
                @include media_query(){
                    grid-row: 2 / 3;
                    grid-column: 1 / 3;
                }
            }
        }
        
        .about_foodloss_en{
            margin-top: 25px;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            letter-spacing: .08em;
            
            h3{
                @include rem(12);
                line-height: 1.8;
            }
            
            p{
                margin-top: 8px;
                @include rem(11);
                font-weight: 400;
                line-height: 1.8;
                text-align: justify;
            }
        }
    }
}






/* *** *** *** *** ***
** apply
*** *** *** *** *** */
.apply{
    border-top: solid 1px $font-color;
    margin-top: 90px;
    padding-top: 90px;
    
    @include media_query(){
        margin-top: 50px;
        padding-top: 50px;
    }
    
    .apply_detail{
        max-width: 762px;
        margin: 45px auto 0;
        padding-inline: 16px;
            
        @include media_query(){
            margin: 30px 0 0;
        }
        
        
        ul{
            list-style: none;
            
            li{
                @include rem(13);
                line-height: 1.8;
                text-align: justify;
            }
        }
        
        p{
            small{
                @include rem(11);
                line-height: 1.5;
                text-align: justify;
            }
        }
    }
    
    .btn_apply{
        margin-top: 10px;
        
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 40px;
            background-color: $font-color;
            color: #fff;
            text-decoration: none;
            @include rem(14);
            // font-weight: bold;
            font-weight: 500;
            letter-spacing: .17em;
            line-height: 2;
            border: solid 1px $font-color;
            
            &[href]{
                transition: background-color .25s, color .25s;
                
                @include rem(16);
                
                &:hover{
                    background-color: transparent;
                    color: $font-color;
                }
            }
            
            @include media_query(){
                height: 45px;
                @include rem(13);
            }
            
            small{
                @include rem(13);
                
                @include media_query(){
                    @include rem(11);
                }
            }
        }
    }
}






/* *** *** *** *** ***
** news
*** *** *** *** *** */
.news{
    border-top: solid 1px $font-color;
    margin-top: 90px;
    padding-top: 90px;
    
    @include media_query(){
        margin-top: 50px;
        padding-top: 50px;
    }
    
    .news_inner{
        max-width: 762px;
        margin: 0 auto;
        padding-inline: 16px;
        
        .news_list{
            margin-top: 45px;
            
            @include media_query(){
                margin-top: 30px;
            }
            
            li{
                display: flex;
                gap: 35px;
                padding: 0 25px;
                
                @include media_query(){
                    flex-direction: column;
                    gap: 0;
                    padding: 0;
                }
                
                & + li{
                    margin-top: 25px;
                }
                
                time{
                    @include rem(12);
                    letter-spacing: .1em;
                    line-height: 2;
                }
                
                a{
                    color: $font-color;
                    text-decoration: none;
                    
                    &:hover{
                        h3{
                            background-color: $font-color;
                            color: #fff;
                        }
                    }
                    
                    h3{
                        padding: 0 5px;
                        font-weight: 500;
                        transition: background-color .25s, color .25s;
                        
                        @include media_query(){
                            margin-left: -5px;
                        }
                    }
                    
                    .news_title_ja{
                        @include rem(12);
                        line-height: 2;
                        letter-spacing: .1em;
                        
                        @include media_query(){
                            line-height: 1.8;
                        }
                    }
                    
                    .news_title_en{
                        margin-top: 3px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 500;
                        @include rem(11);
                        line-height: 2;
                        letter-spacing: .1em;
                        
                        @include media_query(){
                            line-height: 1.8;
                        }
                    }
                }
            }
        }
        
        .news_notfound{
            margin: 50px 16px;
            text-align: center;
        }
        
        .btn_more{
            margin-top: 30px;
            display: flex;
            justify-content: center;
            
            a{
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 5px;
                text-decoration: none;
                color: $font-color;
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
                @include rem(14);
                letter-spacing: .1em;
                
                transition: background-color .25s, color .25s;
                
                &:hover{
                    background-color: $font-color;
                    color: #fff;
                    
                    &::after{
                        filter: brightness(0) invert(1);
                    }
                }
                
                &::after{
                    content: "";
                    display: block;
                    width: 53px;
                    height: 12px;
                    background-image: url(../svg/icon_more.svg);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    
                    transition: filter .25s;
                }
            }
        }
        
    }
}





/* *** *** *** *** ***
** recipe
*** *** *** *** *** */
.recipe{
    border-top: solid 1px $font-color;
    margin-top: 90px;
    padding-top: 90px;
    
    @include media_query(){
        margin-top: 50px;
        padding-top: 50px;
    }
    
    .recipe_inner{
        max-width: 540px;
        margin: 0 auto;
        padding-inline: 16px;
        
        dialog{
            width: 100%;
            max-width: 1200px;
            max-height: 90vh;
            background-color: transparent;
            border: none;
            padding: 0;
            
            @include media_query(){
                max-width: 95vw;
            }
            
            &[open]{
                animation: slide-up .4s ease-out;
            }
            
            .dialog_inner{
                position: relative;
                // padding: 30px;
                
                .btn_close{
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    width: 45px;
                    height: 45px;
                    cursor: pointer;
                    
                    transition: background-color .25s;
                    
                    &:hover{
                        background-color: $font-color;
                        
                        &::before,
                        &::after{
                            background-color: #E1D7C3;
                        }
                    }
                    
                    @include media_query(){
                        width: 30px;
                        height: 30px;
                        top: 10px;
                    }
                    
                    &::before,
                    &::after{
                        position: absolute;
                        top: 50%;
                        left: 0%;
                        content: "";
                        display: block;
                        width: 100%;
                        height: 2px;
                        background-color: $font-color;
                        
                        transition: background-color .25s;
                    }
                    
                    &::before{
                        rotate: 45deg;
                    }
                    
                    &::after{
                        rotate: -45deg;
                    }
                }
                
                img{
                    width: 100%;
                    height: auto;
                    vertical-align: bottom;
                }
            }
            
            &::backdrop{
                background-color: rgba(#000, .6);
            }
        }
        
        @keyframes slide-up {
            0% {
                opacity: 0;
                transform: translate(0, 30px);
            }
            
            100% {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
    
    .recipe_head{
        margin-top: 45px;
            
        @include media_query(){
            margin-top: 30px;
        }
        
        p{
            @include rem(13);
            // font-weight: bold;
            font-weight: 500;
            line-height: 2;
            letter-spacing: .17em;
            text-align: center;
        }
    }
    
    .recipe_list{
        margin-top: 35px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;
        list-style: none;
        
        @include media_query(){
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
        
        .recipe_item{
            position: relative;
            display: block;
            aspect-ratio: 1 / 1;
            
            &[href]{
                transition: opacity .25s;
                
                &:hover{
                    opacity: .7;
                }
            }
            
            &:hover{
                .recipeimg_on{
                    opacity: 1;
                }
            }
            
            .recipeimg_on{
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                
                opacity: 0;
                transition: opacity .25s;
            }
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}


.recipe_more{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    
    a{
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px;
        @include rem(13);
        letter-spacing: .17em;
        // font-weight: bold;
        font-weight: 500;
        color: $font-color;
        text-decoration: none;
        
        transition: background-color .25s, color .25s;
        
        @include media_query(){
            @include rem(12);
            letter-spacing: .09em;
        }
                
        &:hover{
            background-color: $font-color;
            color: #fff;
            
            &::after{
                filter: brightness(0) invert(1);
            }
        }
        
        &::after{
            content: "";
            display: block;
            width: 19px;
            height: 19px;
            background-image: url(../svg/icon_external.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            
            transition: filter .25s;
            
            @include media_query(){
                width: 16px;
                height: 16px;
            }
        }
    }
}





/* *** *** *** *** ***
** event
*** *** *** *** *** */
.event{
    border-top: solid 1px $font-color;
    margin-top: 90px;
    padding-top: 90px;
    
    @include media_query(){
        margin-top: 50px;
        padding-top: 50px;
    }
    
    .event_inner{
        max-width: 900px;
        padding: 0 16px;
        margin-inline: auto;
    }
    
    .event_detail{
        max-width: 540px;
        margin: 50px auto 0;
        
        .event_dl{
            .event_dl_inner{
                
                & + .event_dl_inner{
                    margin-top: 8px;
                    
                    @include media_query(){
                        margin-top: 16px;
                    }
                }
                
                display: grid;
                grid-template-columns: 150px 1fr;
                align-items: center;
                @include rem(12);
                letter-spacing: .1em;
                
                @include media_query(){
                    grid-template-columns: 1fr;
                }
                
                dt{
                    background-color: $font-color;
                    font-weight: 500;
                    color: #E1D7C3;
                    padding: 5px 10px;
                }
                
                dd{
                    margin-left: 10px;
                    // font-weight: bold;
                    font-weight: 500;
                    
                    @include media_query(){
                        margin: 8px 0 0 0;
                    }
                    
                    a,
                    span{
                        color: $font-color;
                        text-decoration: none;
                        padding: 5px;
                    }
                    
                    a{
                        transition: background-color .25s, color .25s;
                        
                        &:hover{
                            background-color: $font-color;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}






/* *** *** *** *** ***
** sponsor
*** *** *** *** *** */
.sponsor{
    border-top: solid 1px $font-color;
    margin-top: 90px;
    padding-top: 90px;
    
    @include media_query(){
        margin-top: 50px;
        padding-top: 50px;
    }
    
    .sponsor_inner{
        max-width: 900px;
        padding: 0 16px;
        margin-inline: auto;
        
        & + .sponsor_inner{
            margin-top: 50px;
        }
        
        .section_title_ja{
            & + .sponsor_l,
            & + .sponsor_s{
                margin-top: 35px;
            
                @include media_query(){
                    margin-top: 25px;
                }
            }
        }
    }
    
    .sponsor_l{
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 40px;
        
        @include media_query(){
            gap: 16px;
        }
        
        li{
            a{
                display: block;
                width: 163px;
                height: 57px;
                padding: 5px;
                
                transition: background-color .25s;
                
                &:hover{
                    background-color: $font-color;
                    
                    img{
                        filter: brightness(0) invert(1);
                    }
                }
                
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    
                    transition: filter .25s;
                }
            }
        }
        
        & + .sponsor_s{
            margin-top: 30px;
        }
    }
    
    .sponsor_s{
        max-width: 600px;
        margin: 0 auto;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px 28px;
        
        @include media_query(){
            gap: 16px;
        }
        
        li{
            a{
                display: grid;
                place-items: center;
                // height: 49px;
                padding: 5px;
                
                transition: background-color .25s;
                
                &:hover{
                    background-color: $font-color;
                    
                    img{
                        filter: brightness(0) invert(1);
                    }
                }
                
                img{
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                    
                    transition: filter .25s;
                }
            }
        }
    }
}
